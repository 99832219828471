import styled from 'styled-components'

import { mq } from '~/styles/mq'
import { theme } from '~/styles/theme'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  width: 100%;
  max-width: 45rem;
  margin: 0 auto;
  padding-left: ${theme.layout.spacing.lg};
  padding-right: ${theme.layout.spacing.lg};

  ${mq.medium} {
    padding-left: ${theme.layout.spacing.lg};
    padding-right: ${theme.layout.spacing.lg};
  }
`
