import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Form } from './styled'

import { useSignInMutation } from '~/@types/schema'
import { ButtonWithLoading } from '~/components/button/with-loading'
import { Input } from '~/components/input'
import { LayoutLogin } from '~/components/layout-login'
import { Logo } from '~/components/logo'
import { Seo } from '~/components/seo'
import { Spacer } from '~/components/spacer'
import { useAppContext } from '~/context/app-context'
import { setAuthCookies } from '~/utils/api/utils'
import { useGenericOnError } from '~/utils/hooks/use-generic-on-error'
import { loginSchema } from '~/utils/validation-schemas'
import { config } from '~/utils/config'
import { useRouter } from 'next/router'

type Inputs = {
  password: string
  username: string
}

export const Login: FC = () => {
  const [globalError, setGlobalError] = useState('')

  // eslint-disable-next-line @typescript-eslint/unbound-method -- see more: https://github.com/react-hook-form/react-hook-form/issues/2887
  const { errors, handleSubmit, register } = useForm<Inputs>({
    mode: 'onSubmit',
    resolver: yupResolver(loginSchema),
  })

  const { setUser } = useAppContext()
  const router = useRouter()
  const { onError } = useGenericOnError()

  const [signIn, { data: signInData, loading: signInLoading }] =
    useSignInMutation({
      onError: (error) => {
        if (error.message === 'Invalid credentials') {
          setGlobalError(error.message)
        } else {
          onError()
        }
      },
    })

  useEffect(() => {
    if (signInData?.signIn) {
      const { firstName, isActiveAdmin, lastName } = signInData.signIn?.user

      if (isActiveAdmin) {
        const { accessToken, expiresAt, refreshToken } =
          signInData.signIn?.session

        setUser({
          firstName,
          lastName,
        })

        setAuthCookies({
          accessToken,
          expiresAt,
          refreshToken,
        })

        void router.push(config.defaultPage)
      } else {
        setGlobalError('Invalid admin credentials or inactive admin')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUser, signInData])

  const handleLogin = ({ password, username }): void => {
    void signIn({ variables: { password, username } })
  }

  return (
    <>
      <Seo title="Log in" />
      <LayoutLogin>
        <Form onSubmit={handleSubmit(handleLogin)}>
          <Logo large light />
          <Spacer
            $direction="vertical"
            space={{
              lg: 'xxl',
              md: 'xl',
              sm: 'lg',
            }}
          />
          <Input
            light
            error={errors.username?.message || globalError}
            label="Email"
            name="username"
            placeholder="jane@example.com"
            register={register}
            type="email"
          />
          <Spacer
            space={{
              sm: 'sm',
            }}
          />
          <Input
            light
            error={errors.password?.message || globalError}
            label="Password"
            name="password"
            placeholder="your password"
            register={register}
            type="password"
          />
          <Spacer
            space={{
              md: 'lg',
              sm: 'md',
            }}
          />
          <ButtonWithLoading
            fullWidth
            isLoading={signInLoading}
            type="submit"
            variant="primary"
          >
            Log in
          </ButtonWithLoading>
        </Form>
      </LayoutLogin>
    </>
  )
}
