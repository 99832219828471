import { DateTime } from 'luxon'
import * as yup from 'yup'
import { getDatetimeISO } from './get-datetime-iso'

// #region common schema validations
const firstName = yup.string().required('First name is required')
const lastName = yup.string().required('Last name is required')
const note = yup.string()

const email = yup.string().email('Email is not valid')

const emailRequired = email.required('Email is required')

const thumbnailTestOptions = {
  message: 'Thumbnail is required',
  name: 'thumbnail',
  test: ({ imageURL, thumbnail }) => {
    return !!imageURL || thumbnail?.length > 0
  },
}

const thumbnailFilesizeTestOptions = {
  message: '',
  name: 'thumbnail',
  // `test` callback needs to be regular function, because we need access `this`
  test: function ({ thumbnail }: { thumbnail: FileList }) {
    const size = thumbnail?.[0]?.size // Bytes
    const sizeMegabytes = (size / 1_000_000).toFixed(1)
    const limit = 2_000_000 // 2 Megabytes

    return size > limit
      ? this.createError({
          message: `Maximum file size is 2 MB. Uploaded image has ${sizeMegabytes} MB`,
        })
      : true
  },
}

const dateTimeTestOptions = {
  message: 'Date and time should be in the future',
  name: 'datetime',
  test: ({ datetime }) => {
    if (!datetime) {
      return false
    }

    const now = DateTime.now()
    const selectedDate = DateTime.fromISO(getDatetimeISO(datetime))

    return selectedDate.diff(now).as('hours') > 0
  },
}

const dateTimeFutureTestOptions = {
  message: 'Scheduled time should be in the future at least 5 minutes',
  name: 'datetime',
  test: ({ datetime }) => {
    if (!datetime) {
      return false
    }

    const inFiveMin = DateTime.now().plus({ minutes: 5 })
    const selectedDate = DateTime.fromISO(getDatetimeISO(datetime))

    return selectedDate.diff(inFiveMin).as('hours') > 0
  },
}

export const loginSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  username: emailRequired,
})

export const adminSchema = yup.object().shape({
  email: emailRequired,
  firstName,
  lastName,
  note,
})

export const adminSchemaEdit = yup.object().shape({
  email: emailRequired,
  firstName,
  lastName,
  note,
})

export const pushNotificationsSchema = yup
  .object()
  .shape({
    datetime: yup.string().required('Date and time are required'),
    headline: yup.string().required('Headline is required'),
    text: yup.string().required('Text is required'),
  })
  .test(thumbnailTestOptions)
  .test(thumbnailFilesizeTestOptions)
  .test(dateTimeTestOptions)
  .test(dateTimeFutureTestOptions)

export const idPageSchema = yup.object().shape({
  bio: yup.string(),
  birthdate: yup.string(),
  email,
  firstName: yup.string().required('Firstname is required'),
  lastName: yup.string().required('Lastname is required'),
  studio: yup.string(),
  username: yup.string().required('Username is required'),
})

export const homepageBannersSchema = yup
  .object()
  .shape({
    callToAction: yup.string().required('Call to action is required'),
    headline: yup.string().required('Headline is required'),
    link: yup
      .string()
      .required('Link is required')
      .url('Link should be in a valid format, e.g. "https://barrys.com"'),
  })
  .test(thumbnailTestOptions)
  .test(thumbnailFilesizeTestOptions)

export const OnDemandSectionSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
})

export const OnDemandCollectionSchema = yup
  .object()
  .shape({
    title: yup.string().required('Title is required'),
  })
  .test(thumbnailTestOptions)

export const NewCollectionSectionModalContentSchema = yup.object().shape({
  name: yup.string().required('Section name is required'),
})

export const EmailModalSchema = yup.object().shape({
  message: yup.string().required('Message is required'),
})
